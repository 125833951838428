import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import AccountLayout from "./account/AccountLayout";
import Login from "./account/Login";
import Register from "./account/Register";

const Account = () => {
    const { auth } = useAuth();
    const { userId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [loginPage, setLoginPage] = useState(location.state?.login || true);

    const handleSetPage = (e) => {
        e.preventDefault();
        setLoginPage(!loginPage);
    };

    useEffect(() => {
        // Change default route WHEN authenticated
        if (auth?.accessToken && location.pathname === '/account') {
            navigate('/account/overzicht');
        }
    }, [location]);

    return (
        <div className="container account">
            <h2 className="center">Mijn Account</h2>
            {/* {console.log(JSON.stringify(auth))} */}
            {
                (userId || auth?.accessToken)
                    ? <AccountLayout />
                    : loginPage
                        ? <Login handleSetPage={handleSetPage} />
                        : <Register handleSetPage={handleSetPage} />
            }
        </div>
    );
};

export default Account;