import { useEffect, useState } from 'react';
import useWindowSize from '../hooks/useWindowSize';
import { ReactComponent as Logo } from '../img/Bambi.svg'
import { ReactComponent as FullLogo } from '../img/Bambino-Bambi.svg'
import { IoMenu } from "react-icons/io5";
import { MdPerson, MdOutlineShoppingBag } from "react-icons/md";
import ListItem from './ListItem';
import useScrollSize from '../hooks/useScrollSize';
import { Link } from 'react-router-dom';
import { getCartTotal } from "../features/shoppingCart/shopItemsSlice";
import { useSelector } from 'react-redux';

const Nav = () => {
    const shopCartTotal = useSelector(getCartTotal);
    const { width } = useWindowSize();
    const { scrollHeight } = useScrollSize();
    const [showMenu, setShowMenu] = useState(false);
    const [isMobile, setIsMobile] = useState(null);

    const toggleMenu = () => {
        isMobile && setShowMenu(!showMenu);
    };

    useEffect(() => {
        setIsMobile(width > 768 ? false : true);
        setShowMenu(width > 768 ? true : false);
    }, [width]);

    useEffect(() => {
        isMobile && showMenu ?
            document.body.classList.add('stop-scrolling') :
            document.body.classList.remove('stop-scrolling')
    }, [isMobile, showMenu]);

    return (
        <>
            <div className={isMobile && showMenu ? 'closeMenuClick' : 'closeMenuClick-hide'} style={{ position: 'absolute', height: scrollHeight }} onClick={toggleMenu}></div>
            <div id="top" className="navBar container" onClick={isMobile && showMenu ? toggleMenu : null}>
                <div className="mobileLogoTitle">
                    <Link to="/">
                        <FullLogo className="fullLogo" />
                    </Link>
                </div>
                <button className="btnMobileLogo" onClick={toggleMenu}>
                    <div>
                        <IoMenu className='burger' />
                    </div>
                </button>
                <ul className={`nav ${showMenu ? 'show' : 'hide'}`}>
                    <ListItem toggleMenu={toggleMenu} link="/">
                        {isMobile ?
                            <Logo className="logo" /> :
                            <FullLogo className="logo" />
                        }
                    </ListItem>
                    <ListItem toggleMenu={toggleMenu} link="/kampjes">kampjes</ListItem>
                    <ListItem toggleMenu={toggleMenu} link="/feestjes">feestjes</ListItem>
                    <ListItem toggleMenu={toggleMenu} link="/drop&shop">Drop & Shop</ListItem>
                    {/* <ListItem toggleMenu={toggleMenu} link="/over_ons">over ons</ListItem> */}
                </ul>
                <div className="navSession">
                    <Link to="/account">
                        <MdPerson />
                    </Link>
                    <Link to="/cart" className='cart' data-items-in-bag={shopCartTotal}>
                        <MdOutlineShoppingBag />
                    </Link>
                </div>
            </div>

            {/* <div className={isMobile && showMenu ? 'closeMenuClick' : 'closeMenuClick-hide' } style={{position: 'absolute', height: scrollHeight}} onClick={toggleMenu}></div>
            <div className="navBar container" onClick={isMobile && showMenu ? toggleMenu : null}>
                <div className={`mobileLogoTitle ${isMobile ? 'show' : 'hide'}`}>
                    <Link to="/">
                        <FullLogo className="fullLogo" />
                    </Link>
                </div>
                <button className={`btnMobileLogo ${isMobile ? 'show' : 'hide'}`} onClick={toggleMenu}>
                    <div>
                        <IoMenu className='burger' />
                    </div>
                </button>
                <ul className={`nav ${showMenu ? 'show' : 'hide'}`}>
                    <ListItem toggleMenu={toggleMenu} link="/">
                        { isMobile ?
                            <Logo className="logo" /> :
                            <FullLogo className="logo" />
                        }
                    </ListItem>
                    <ListItem toggleMenu={toggleMenu} link="/feestjes">feestjes</ListItem>
                    <ListItem toggleMenu={toggleMenu} link="/drop_shop">drop & shop</ListItem>
                    <ListItem toggleMenu={toggleMenu} link="/knutseldag">knutseldag</ListItem>
                    <ListItem toggleMenu={toggleMenu} link="/over_ons">over ons</ListItem>
                </ul>
            </div> */}

        </>
    )
}

export default Nav;