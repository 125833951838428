import { useMemo, useState, useEffect } from "react";

// Timer Function
export const Time = {
    seconds: 1000,
    minutes: 60 * 1000,
    hours: 60 * 60 * 1000,
    days: 24 * 60 * 60 * 1000
}

const Timer = ({ deadline = new Date().toString(), handleReachedDeadline }) => {
    const parsedDeadline = useMemo(() => Date.parse(deadline), [deadline]);
    const [time, setTime] = useState(parsedDeadline - Date.now());

    useEffect(() => {
        const interval = setInterval(
            () => {
                setTime(parsedDeadline - Date.now())
                // Time's up
                if (parsedDeadline - Date.now() < 0) {
                    clearInterval(interval);
                    handleReachedDeadline();
                }
            },
            1000
        );

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {
                Object.entries({
                    //d: time / Time.days,
                    //u: (time / Time.hours) % 24,
                    m: (time / Time.minutes) % 60,
                    s: (time / Time.seconds) % 60
                }).map(([label, value]) => Math.floor(value) >= 0 ? (
                    `${Math.floor(value)}`.padStart(2, "0") + label
                )
                    :
                    ""
                )
            }
        </>
    )
}

export default Timer;