const CheckOut = () => {
    const opts = [{ "tag": "PM_2379", "name": "Payconiq", "translations": { "name": { "nl_NL": "Payconiq" } }, "image": "/payment_methods/138.svg", "paymentMethods": [{ "id": 2379, "name": "Payconiq", "description": "Payconiq", "translations": { "name": { "nl_NL": "Payconiq" }, "description": { "en_GB": "Payconiq" } }, "image": "/payment_methods/138.svg", "options": [], "settings": null, "minAmount": 1, "maxAmount": 999999 }], "requiredFields": null }, { "tag": "PM_436", "name": "Bancontact", "translations": { "name": { "nl_NL": "Bancontact" } }, "image": "/payment_methods/2.svg", "paymentMethods": [{ "id": 436, "name": "Bancontact", "description": "Bancontact was formaly knows as MisterCash.", "translations": { "name": { "nl_NL": "Bancontact" }, "description": { "en_GB": "Bancontact was formaly knows as MisterCash.", "nl_NL": "U kunt met Bancontact vertrouwd, veilig en gemakkelijk betalen via internetbankieren van uw eigen bank, wanneer u een Belgische bankrekening heeft." } }, "image": "/payment_methods/2.svg", "options": [], "settings": null, "minAmount": 1, "maxAmount": 1000000 }], "requiredFields": null }]

    return (
        <div className="container">
            <h2>Kies een betaalwijze:</h2>
            <div className='payOpts' >
                {
                    opts.map((paymentMethod) => (
                        <div key={paymentMethod.tag} className="payOpt">
                            <input type="radio" name="opt" id={paymentMethod.tag} />
                            <label className="payOpt" htmlFor={paymentMethod.tag}>
                                <img id='img' src={require(`./payment-images${paymentMethod.image}`)} alt={paymentMethod.name} />
                                {paymentMethod.name}
                            </label>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default CheckOut