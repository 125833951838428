import { useRef, useState } from "react";
import axios from "../../api/axios";

const RESET_PWD_URL = "/user/resetPwd";

const ResetPwdSendMail = () => {
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState("");
    const [email, setEmail] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(RESET_PWD_URL,
                JSON.stringify({ email }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setErrMsg(`Mail met instructies verzonden naar ${email}!`);
            setEmail('');

        } catch (err) {
            if (!err?.response) {
                setErrMsg('Geen antwoord van de Server');
            } else if (err.response?.status === 401) {
                setErrMsg('Geen account gevonden voor dit e-mail adres');
            } else {
                setErrMsg('Wachtwoord opnieuw instellen mislukt');
            }
            errRef.current.focus();
        }
    };

    return (
        <>
            <h3>Wachtwoord vergeten?</h3>
            <p>
                Om je wachtwoord opnieuw in te stellen, vul je <b>e-mail adres</b> in.<br />
                Je zal verdere <b>instructies</b> ontvangen per mail.
            </p>
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    required
                    inputMode="email"
                    placeholder="E-mail adres"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <p className="errMsgForm" ref={errRef}>{errMsg}</p>
                <input type="submit" value="Verstuur" />
            </form>
        </>
    )
}
export default ResetPwdSendMail;