import { useLocation } from "react-router-dom"

const OrderComplete = () => {
    const location = useLocation();

    const order = () => {
        const searcher = location.search.substring(1).split('&');
        const params = {};
        searcher.forEach((p) => {
            const pairs = p.split('=');
            params[pairs[0]] = pairs[1];
        })

        return params;
    }
    // orderId=2355480447Xd08f2&orderStatusId=100&paymentSessionId=2355480447
    // orderId=2364467157X00124&orderStatusId=-90&paymentSessionId=2364467157
    const {
        // orderId,
        orderStatusId,
        // paymentSessionId
    } = order();

    // Status Codes
    const orderStatus = {
        "100": 'PAID',
        "95": 'AUTHORIZED',
        "50": 'PENDING',
        "20": 'CREATED',
        "-60": 'FAILED',
        "-80": 'EXPIRED',
        "-90": 'CANCELLED',
    };

    let content;
    switch (Number(orderStatusId)) {
        case 100:
            content =
                <>
                    <h2 className="center">Bestelling afgerond</h2>
                    <p>Bedankt voor je bestelling, we zien jou graag binnenkort!</p>
                </>;
            break;
        case 50:
            content =
                <>
                    <h2 className="center">Bestelling in uitvoering</h2>
                    <p>De betaling is nog niet bij ons binnengekomen, uw bestelling is pas definitief na ontvangst van de betaling.</p>
                </>;
            break;
        case -60:
            content =
                <>
                    <h2 className="center">Bestelling kon niet worden afgerond!</h2>
                    <p>Oeps, er ging iets mis! Neem contact met ons op, indien het probleem zich blijft voordoen.</p>
                </>;
            break;
        case -90:
            content =
                <>
                    <h2 className="center">Bestelling kon niet worden afgerond!</h2>
                    <p>U heeft de betaling geannuleerd.</p>
                </>;
            break;

        default:
            break;
    }

    return (
        <div className="container no-color">
            {content}
        </div>
    )
}
export default OrderComplete