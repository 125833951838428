import { configureStore } from "@reduxjs/toolkit";
import shopItemsReducer from '../features/shoppingCart/shopItemsSlice';
import kampjesReducer from '../features/kampjes/kampjesSlice';
import kampDetailsReducer from "../features/kampjes/kampDetailsSlice";
import kidsReducer from '../features/kids/kidsInProfileSlice';

export const store = configureStore({
    reducer: {
        shopItems: shopItemsReducer,
        kampjes: kampjesReducer,
        kampDetails: kampDetailsReducer,
        kids: kidsReducer
    }
});