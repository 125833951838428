import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
const KIDS_URL = 'user/kids';

const initialState = {
    kids: [],
    status: 'idle',
    error: null
};

export const fetchKids = createAsyncThunk(
    'kids/fetchKids',
    async (accessToken) => {
        const response = await axios.get(KIDS_URL,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    }
);

const kidsSlice = createSlice({
    name: 'kids',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchKids.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchKids.fulfilled, (state, action) => {
                state.status = 'success';
                state.kids = action.payload;
            })
            .addCase(fetchKids.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});


export const selectAllKids = (state) => state.kids.kids;
export const getKidsStatus = (state) => state.kids.status;
export const getKidsError = (state) => state.kids.error;

export default kidsSlice.reducer;