import { Link, useNavigate } from "react-router-dom";

const Missing = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <div className="container no-color center">
            <h1>Oeps!</h1>
            <h2>De gevraagde pagina bestaat niet.</h2>
            <p><Link onClick={goBack}>Klik hier</Link> om terug te keren.</p>
        </div>
    )
}

export default Missing