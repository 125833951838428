import { Link, useLocation } from "react-router-dom";

const ListItem = ({ toggleMenu, link, children }) => {
    const location = useLocation();

    return (
        <li>
            <Link id={ location.pathname === link ? "selected" : ""} to={link} onClick={toggleMenu}>
                <div>{children}</div>
            </Link>
        </li>
    )
};

export default ListItem;