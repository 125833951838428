import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollSize = () => {
    const [scrollSize, setScrollSize] = useState({
        scrollWidth: undefined,
        scrollHeight: undefined
    });

    const location = useLocation();

    useEffect(() => {
        const handleScrollResize = () => {
            const scrollHeight = document.body.scrollHeight;
            const scrollWidth = document.body.scrollWidth;
            
            setScrollSize({
                scrollWidth: scrollWidth,
                scrollHeight: scrollHeight
            });

            window.scrollTo(0, 0);
        }

        handleScrollResize();
    }, [location])

    return scrollSize;
}

export default useScrollSize;