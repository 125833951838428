import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
const KAMPJES_URL = '/kampjes'

export let prevKampId = null;

const initialState = {
    kampDetails: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
};

export const fetchKampDetails = createAsyncThunk(
    'kampjes/fetchById',
    async (kampId) => {
        prevKampId = kampId;
        const response = await axios.get(`${KAMPJES_URL}/${kampId}`);
        return response.data;
    })

const kampDetailsSlice = createSlice({
    name: 'kampDetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchKampDetails.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchKampDetails.fulfilled, (state, action) => {
                state.status = 'success';
                state.kampDetails = action.payload;
            })
            .addCase(fetchKampDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const selectAllKampDetails = (state) => state.kampDetails.kampDetails;
export const getKampDetailsStatus = (state) => state.kampDetails.status;
export const getKampDetailsError = (state) => state.kampDetails.error;

export default kampDetailsSlice.reducer;