import { useNavigate } from "react-router-dom";


const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <div className="container no-color center">
            <h2>Geen Toegang!</h2>
            <p>Je hebt geen toegang tot de gevraagde pagina.</p>
            <button onClick={goBack}>Ga Terug</button>
        </div>
    );
};

export default Unauthorized;