import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { lazy, Suspense } from "react";
import { AuthProvider } from "../context/AuthProvider";
import { store } from "../app/store";
import { Provider } from "react-redux";

// Load main items
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Loading from "../components/utilities/Loading";
import ScrollToAnchor from "../components/utilities/ScrollToAnchor";
import Account from "../components/Account";
import PersistLogin from "./PersistLogin";
import RequireAuth from "./RequireAuth";
import Unauthorized from "../components/Unauthorized";
import Missing from "../components/Missing";
import ShopCartList from "../features/shoppingCart/ShopCartList";
import CheckOut from "../features/shoppingCart/CheckOut";
import OrderComplete from "../features/shoppingCart/OrderComplete";
import ResetPwd from "../components/account/ResetPwd";

// Lazy load pages
const Home = lazy(() => import("../components/pages/Home"));
const Feestjes = lazy(() => import("../components/pages/Feestjes"));
const DropShop = lazy(() => import("../components/pages/DropShop"));
// const Knutseldag = lazy(() => import("../components/pages/Knutseldag"));
const OverOns = lazy(() => import("../components/pages/OverOns"));
const Kampjes = lazy(() => import("../components/pages/Kampjes"));
const KampDetails = lazy(() => import("../components/kampjes/KampDetails"));
const Cookies = lazy(() => import("../components/pages/Cookies"));
const AlgemeneVoorwaarden = lazy(() => import("../components/pages/AlgemeneVoorwaarden"));
// Lazy load account pages
const BestelOverzicht = lazy(() => import("../components/account/BestelOverzicht"));
const Profile = lazy(() => import("../components/account/Profile"));
const Kids = lazy(() => import("../components/account/Kids"));
// Lazy load Admin pages
const AdminAgenda = lazy(() => import("../components/account/admin/Agenda"));
const AdminKamp = lazy(() => import("../components/account/admin/Kampjes"));
const AdminKampDagen = lazy(() => import("../components/account/admin/KampDagen"));
const AdminKlanten = lazy(() => import("../components/account/admin/Klanten"));
const AdminTransactions = lazy(() => import("../components/account/admin/Transactions"));

// API base url
const API_URL = "https://rest-api.bambinolier.be/";
// const API_URL = "http://localhost:3500/";

const ROLES = {
    "User": 2001,
    "Admin": 5150
};

const Routes = () => {

    const HeaderLayout = () => (
        <AuthProvider>
            <Provider store={store}>
                <Nav />
                <div className="page">
                    <Suspense fallback={<Loading />}>
                        <Outlet />
                    </Suspense>
                </div>
                <ScrollToAnchor />
                <Footer />
            </Provider>
        </AuthProvider>
    );

    // Define public routes accessible to all users
    const routesForPublic = [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/feestjes',
            element: <Feestjes />
        },
        {
            path: '/drop&shop',
            element: <DropShop API_URL={API_URL} />
        },
        // {
        //     path: '/knutseldag',
        //     element: <Knutseldag />
        // },
        {
            path: '/over_ons',
            element: <OverOns />
        },
        {
            path: '/kampjes',
            element: <Kampjes />
        },
        {
            path: '/kampjes/:kamp',
            element: <KampDetails API_URL={API_URL} />
        },
        {
            path: '/cookies',
            element: <Cookies />
        },
        {
            path: '/algemene_voorwaarden',
            element: <AlgemeneVoorwaarden />
        },
        {
            path: '/checkout',
            element: <CheckOut />
        },
        {
            path: '/resetPwd',
            element: <ResetPwd />
        },
        {
            path: '/unauthorized',
            element: <Unauthorized />
        }
    ];

    // Define routes accessible only to authenticated users
    const routesForAuthenticationOnly = [
        {
            element: <PersistLogin />,
            children: [
                {
                    path: '/account',
                    element: <Account />,
                    children: [
                        {
                            element: <RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />,
                            children: [
                                {
                                    path: '/account/overzicht',
                                    element: <BestelOverzicht />
                                },
                                {
                                    path: '/account/profiel',
                                    element: <Profile />
                                },
                                {
                                    path: '/account/kids',
                                    element: <Kids />
                                }
                            ]
                        },
                        {
                            element: <RequireAuth allowedRoles={[ROLES.Admin]} />,
                            children: [
                                {
                                    path: '/account/admin/agenda',
                                    element: <AdminAgenda API_URL={API_URL} />
                                },
                                {
                                    path: '/account/admin/kampjes',
                                    element: <AdminKamp API_URL={API_URL} />
                                },
                                {
                                    path: '/account/admin/kampdagen',
                                    element: <AdminKampDagen API_URL={API_URL} />
                                },
                                {
                                    path: '/account/admin/klanten',
                                    element: <AdminKlanten API_URL={API_URL} />
                                },
                                {
                                    path: '/account/admin/transactions',
                                    element: <AdminTransactions API_URL={API_URL} />
                                },
                                {
                                    path: '/account/admin/nieuwsbrief',
                                    element: <h2 className="center">Nieuwsbrief</h2>
                                },
                                {
                                    path: '/account/admin/instellingen',
                                    element: <h2 className="center">Instellingen</h2>
                                },
                            ]
                        }
                    ]
                },
                {
                    path: '/cart',
                    element: <RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />,
                    children: [
                        {
                            path: '/cart',
                            element: <ShopCartList />
                        }
                    ]
                },
                {
                    path: '/ordercomplete',
                    element: <OrderComplete />
                },
            ]
        }
    ];


    const router = createBrowserRouter([
        {
            element: <HeaderLayout />,
            children: [
                ...routesForPublic,
                ...routesForAuthenticationOnly,
                // ...(!token ? routesForNotAuthenticatedOnly: []),
                { // Catch All
                    path: '*',
                    element: <Missing />
                }
            ]
        }
    ]);

    return <RouterProvider router={router} />;
}

export default Routes;