import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import axios from "../../api/axios";
const LOGIN_URL = "/auth";

function Login({ handleSetPage }) {
    const { setAuth, persist, setPersist } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/account/overzicht";

    const emailRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        emailRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [email, pwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ email, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            const userId = response?.data?.userId;
            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            const kids = response?.data?.kids;
            // console.log(userId, email, roles);
            setAuth({ userId, email, roles, kids, accessToken });
            // setAuth({ email, pwd, roles, accessToken });
            setEmail('');
            setPwd('');
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Geen antwoord van de Server');
            } else if (err.response?.status === 400) {
                setErrMsg('E-mail en wachtwoord zijn verplicht');
            } else if (err.response?.status === 401) {
                setErrMsg('Foutieve combinatie van e-mail en wachtwoord');
            } else {
                setErrMsg('Login mislukt');
            }
            errRef.current.focus();
        }
    };

    const togglePersist = () => {
        setPersist(prev => !prev);
    };

    useEffect(() => {
        localStorage.setItem("persist", persist);
    }, [persist]);

    return (
        <div className="no-color">
            <p ref={errRef}>{errMsg}</p>
            <h3>Aanmelden</h3>
            <form onSubmit={handleSubmit}>
                <input
                    ref={emailRef}
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    inputMode="email"
                    type="email"
                    placeholder="E-mail adres"
                />
                <input
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                    type="password"
                    placeholder="Wachtwoord"
                />
                <div className="checkbox">
                    <input
                        onChange={togglePersist}
                        checked={persist}
                        type="checkbox"
                        id="onthouden"
                    />
                    <label htmlFor="onthouden">Blijf ingelogd op dit apparaat.</label>
                </div>
                <input type="submit" value="Aanmelden" />
                <Link to={"/resetPwd"}>Wachtwoord vergeten?</Link>
            </form>
            <p>Nog geen account? <Link onClick={handleSetPage}>Start hier</Link></p>
        </div>
    )
}

export default Login;