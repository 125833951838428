import { createSlice } from "@reduxjs/toolkit";
import { Time } from "../../components/utilities/Timer";

const initialState = {
    shopItems: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    deadline: null,
    dbId: null
};

const shopItemsSlice = createSlice({
    name: 'shopItems',
    initialState,
    reducers: {
        clearDeadline: {
            reducer(state) {
                state.deadline = null;
            }
        },
        setDeadline: {
            reducer(state) {
                state.deadline = new Date(Date.now() + Time.minutes * 15).toString();
                // state.deadline = new Date(Date.now() + Time.minutes * 15).toString();
            }
        },
        clear: {
            reducer(state) {
                state.shopItems = []
            }
        },
        itemAdd: {
            reducer(state, action) {
                state.shopItems.push(action.payload)
            },
            prepare(item, kids) {
                return {
                    payload: {
                        ...item,
                        kids
                    }
                }
            }
        },
        itemRemove: {
            reducer(state, action) {
                state.shopItems = state.shopItems.filter(item => item.id !== action.payload.id)
            }
        },
        setDbId: {
            reducer(state, action) {
                state.dbId = action.payload;
            }
        }
    }
});

export const parseDate = (unixTimeStamp) => `${new Date(unixTimeStamp * 1000).getDate().toString().padStart(2, "0")}.${(new Date(unixTimeStamp * 1000).getMonth() + 1).toString().padStart(2, "0")}.${new Date(unixTimeStamp * 1000).getFullYear()}`;

export const selectAllShopItems = (state) => state.shopItems.shopItems;
export const getCartTotal = (state) => state.shopItems.shopItems.length;
export const getDeadline = (state) => state.shopItems.deadline;
export const getDbId = (state) => state.shopItems.dbId;

export const { itemAdd, itemRemove, clear, setDeadline, clearDeadline, setDbId } = shopItemsSlice.actions;

export default shopItemsSlice.reducer;
