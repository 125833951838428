import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaInfoCircle, FaCheck, FaTimes } from "react-icons/fa";
import ResetPwdSendMail from "./ResetPwdSendMail";
import axios from "../../api/axios";

const TIMEOUT_GOBACK = 1500;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const RESET_PWD_URL = "/user/resetPwd";

const ResetPwd = () => {
    const navigate = useNavigate();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState("");

    const location = useLocation();
    const resetData = () => {
        const searcher = location.search.substring(1).split('&');
        const params = {};
        searcher.forEach((p) => {
            const pairs = p.split('=');
            params[pairs[0]] = pairs[1];
        });

        return params;
    };

    const {
        token,
        email
    } = resetData();

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.put(RESET_PWD_URL,
                JSON.stringify({ email, token, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setErrMsg(`Nieuw wachtwoord voor ${email} ingesteld, log in met je nieuwe wachtwoord!`);
            setPwd('');
            setMatchPwd('');
            setTimeout(
                () => navigate('/account')
                , TIMEOUT_GOBACK);

        } catch (err) {
            if (!err?.response) {
                setErrMsg('Geen antwoord van de Server');
            } else if (err.response?.status === 401) {
                setErrMsg('Ongeldige link (token vervallen), vraag een nieuw wachtwoord aan!');
            } else {
                setErrMsg('Wachtwoord opnieuw instellen mislukt');
            }
            errRef.current.focus();
        }
    };

    return (
        <div className="no-color container">
            {
                token && email
                    ?
                    <>
                        <h3>Wachtwoord instellen</h3>
                        <p>Nieuw wachtwoord voor <b>{email}</b> instellen.</p>
                        <p className="errMsgForm" ref={errRef}>{errMsg}</p>
                        <form onSubmit={handleSubmit}>
                            <fieldset>
                                <legend>
                                    Kies een nieuw wachtwoord
                                </legend>
                                <span className="validation">
                                    <FaCheck className={validPwd ? "valid" : "hide"} />
                                    <FaTimes className={validPwd || !pwd ? "hide" : "invalid"} />
                                </span>
                                <input
                                    required
                                    type="password"
                                    placeholder="Wachtwoord"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    onFocus={() => setPwdFocus(true)}
                                    onBlur={() => setPwdFocus(false)}
                                />
                                <p className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                    <FaInfoCircle /> 8 to 24 tekens.<br />
                                    Moet bestaan uit hoofdletters en kleine letters, een nummer en een speciaal teken.<br />
                                    Toegestane speciale tekens: ! @ # $ %
                                </p>
                                <span className="validation">
                                    <FaCheck className={validMatch && matchPwd ? "valid" : "hide"} />
                                    <FaTimes className={validMatch || !matchPwd ? "hide" : "invalid"} />
                                </span>
                                <input
                                    required
                                    type="password"
                                    placeholder="Bevestig wachtwoord"
                                    onChange={(e) => setMatchPwd(e.target.value)}
                                    value={matchPwd}
                                    onFocus={() => setMatchFocus(true)}
                                    onBlur={() => setMatchFocus(false)}
                                />
                                <p className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                    <FaInfoCircle /> Wachtwoorden komen niet overeen.
                                </p>
                            </fieldset>
                            <input disabled={![validPwd || pwd, validMatch].every(el => Boolean(el) === true)} type="submit" value="Opslaan" />
                        </form>
                    </>
                    :
                    <>
                        <ResetPwdSendMail />
                    </>
            }
        </div>
    )
}

export default ResetPwd;