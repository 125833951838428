import ProfileLayout from "./ProfileLayout";

const Register = ({ handleSetPage }) => {
    return (
        <ProfileLayout
            title="Register"
            handleSetPage={handleSetPage}
        />
    )
}
export default Register;